import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationEn from "./locales/en/translation.json";
import translationEs from "./locales/es/translation.json";
import translationGl from "./locales/gl/translation.json";

i18n
    .use(XHR)
    .use(LanguageDetector)
    .init({
        debug: false,
        fallbackLng: "es",
        keySeparator: ".",
        interpolation: {
            escapeValue: false,
        },

        resources: {
            en: {
                translations: translationEn,
            },
            es: {
                translations: translationEs,
            },
            gl: {
                translations: translationGl,
            },
        },
        ns: ["translations"],
        defaultNS: "translations",
        detection: {
            order: ['localStorage', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['localStorage'],
        },
    });

export default i18n;