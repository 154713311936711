import styled from "styled-components";

export const StyledSVGContainer = styled.div<{ width: string | number; height: string | number }>`
  width: ${({ width }) => (typeof width === "number" ? `${width}px` : width)};
  height: ${({ height }) => (typeof height === "number" ? `${height}px` : height)};
  display: inline-block;

    .img-hover {
        transition: filter 0.3s ease;
    }

    .img-hover:hover {
        filter: brightness(0) saturate(100%) invert(25%) sepia(99%) saturate(1613%) hue-rotate(308deg) brightness(102%) contrast(111%);
        //fill: #FC248A; /* Color al hacer hover */
    }
`;