import {lazy, Suspense, useEffect, useState} from "react";
import {Switch, Route, useLocation} from "react-router-dom";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import routes from "./config";
import { Styles } from "../styles/styles";
import CookieBanner from "../components/layout/CookieBanner";

const Router = () => {
    const location = useLocation();
    const [isFixed, setIsFixed] = useState(true);

    useEffect(() => {
        const currentRoute = routes.find(route => {
            if (Array.isArray(route.path)) {
                return route.path.includes(location.pathname);
            }
            return route.path === location.pathname;
        });

        if (currentRoute) {
            setIsFixed(currentRoute.fixed);
        }
    }, [location.pathname]);
  return (
    <Suspense fallback={null}>
      <Styles />
      <Header fixed={isFixed}  />
      <Switch>
        {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
      </Switch>
      <Footer />
      <CookieBanner />
    </Suspense>
  );
};

export default Router;
