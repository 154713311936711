import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import Data from "../../../content/Data.json"

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Large,
  FooterContainer,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" hoverEvent={true}/>
      </a>
    );
  };

  const currentYear = new Date().getFullYear();
  const sinceYear = parseInt(Data.company.since, 10);

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={24}>
              <Title>{t("footer.contact.title")}</Title>
              <Large to="/">{Data.company.email}</Large>
            </Col>
            <Col lg={8} md={8} sm={12} xs={24}>
              <Title>{t("footer.policy.title")}</Title>
              <Large to="/politica-de-privacidad">{t("footer.policy.privacy.title")}</Large>
              <Large to="/politica-de-cookies">{t("footer.policy.cookie.title")}</Large>
              <Large to="/aviso-legal">{t("footer.policy.legal.title")}</Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={24}>
                <Label htmlFor="select-lang">{t("footer.language.title")}</Label>
                <LanguageSwitchContainer>
                  <LanguageSwitch onClick={() => handleChange("es")}>
                    <SvgIcon
                        src="spain.svg"
                        aria-label="homepage"
                        width="30px"
                        height="30px"
                    />
                  </LanguageSwitch>
                  <LanguageSwitch onClick={() => handleChange("gl")}>
                    <SvgIcon
                        src="galician.svg"
                        aria-label="homepage"
                        width="30px"
                        height="30px"
                    />
                  </LanguageSwitch>
                  <LanguageSwitch onClick={() => handleChange("en")}>
                    <SvgIcon
                        src="united-states.svg"
                        aria-label="homepage"
                        width="30px"
                        height="30px"
                    />
                  </LanguageSwitch>
                </LanguageSwitchContainer>
              </Col>
          </Row>

        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                  hoverEvent={true}
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <SocialLink
                href="https://instagram.com/nortapp"
                src="instagram.svg"
              />
              <SocialLink
                href="https://www.linkedin.com/in/nortapp/"
                src="linkedin.svg"
              />
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
      <Extra>
        <Container border={false}>
          <Row
              justify="space-between"
              align="middle"
              style={{ paddingTop: "1rem" }}
          >
           <small>
             Copyright © {sinceYear === currentYear ? sinceYear : `${sinceYear} - ${currentYear}`} {Data.company.name}
           </small>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
