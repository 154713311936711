const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
    fixed: false
  },
  {
    path: ["/contacto"],
    exact: true,
    component: "Contact",
    fixed: true
  },
  {
    path: ["/posiciones-abiertas"],
    exact: true,
    component: "Careers",
    fixed: true
  },
  {
    path: ["/politica-de-privacidad"],
    exact: true,
    component: "Policy",
    fixed: true
  },
  {
    path: ["/politica-de-cookies"],
    exact: true,
    component: "Policy",
    fixed: true
  },
  {
    path: ["/aviso-legal"],
    exact: true,
    component: "Policy",
    fixed: true
  },
  {
    path: ["/posiciones-abiertas"],
    exact: true,
    component: "Carrers",
    fixed: true
  },
];

export default routes;
