import { SvgIconProps } from "../types";
import { StyledSVGContainer } from "./styles";
import { useEffect, useRef } from "react";

export const SvgIcon = ({ src, width, height, hoverEvent }: SvgIconProps) => {
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const imgElement = imgRef.current;
    if (hoverEvent && imgElement) {
      imgElement.classList.add("img-hover");
    }
  }, [hoverEvent]);

  return (
      <StyledSVGContainer width={width} height={height} data-hover-event={hoverEvent}>
        <img
            ref={imgRef}
            src={`/images/svg/${src}`}
            width={width}
            height={height}
            alt={src}
            aria-label={src}
            style={{ cursor: 'pointer' }} // Añadir cursor pointer para indicar que es clicable
        />
      </StyledSVGContainer>
  );
};
