import { CSSProperties } from 'react';

export const bannerText: CSSProperties = {
    color: '#FFFFFF',
    fontSize: '1em'
}

export const bannerStyle: CSSProperties = {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: '#062342',
    color: 'red !important',
    textAlign: 'center',
    padding: '10px',
    zIndex: 1000,
    transition: 'opacity 0.5s ease-out',
};

export const linkStyle: CSSProperties = {
    color: '#FC248A',
    marginLeft: '10px',
};

export const buttonStyle: CSSProperties = {
    padding: '0.5em'
};

export const overlayStyle: CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
};
