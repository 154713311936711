import React, { useState, useEffect } from 'react';
import {bannerStyle, linkStyle, buttonStyle, overlayStyle, bannerText} from './styles';
import {Button} from "../../common/Button";
import {Col, notification, Row} from "antd";
import { withTranslation, TFunction } from "react-i18next";

const CookieBanner = ({ t }: { t: TFunction }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        const cookiesAccepted = localStorage.getItem('cookiesAccepted');
        if (!cookiesAccepted) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookiesAccepted', 'true');
        setIsAnimating(true);
        setTimeout(() => setIsVisible(false), 500);
    };

    const handleDecline = () => {
        setIsAnimating(true);
        setTimeout(() => setIsVisible(false), 500);
        notification["warn"]({
            message: t("messages.warn"),
            description: t("cookies.warn"),
            duration: 5
        });
    };

    if (!isVisible) {
        return null;
    }

    return (
        <>
            <div style={overlayStyle}></div>
            <div style={{ ...bannerStyle, opacity: isAnimating ? 0 : 1 }}>
                <p style={bannerText}>
                    {t("cookies.message")}
                    <a href="/politica-de-cookies" style={linkStyle}>{t("cookies.link")}</a>
                </p>

                <Row justify="center" align="middle" id="consent">
                    <Col lg={12} md={12} sm={12} xs={12} style={buttonStyle}>
                        <Button onClick={handleAccept} color={"#FC248A"} >{t("cookies.actions.accept")}</Button>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} style={buttonStyle}>
                        <Button onClick={handleDecline} color={"#FC248A"}>{t("cookies.actions.denny")}</Button>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default withTranslation()(CookieBanner);
